.customModal{max-width: 1000px;}
.btn-close{
    width: 27px;
    background-color: red;
    border-radius: 7px;
    border: red;
    font-size: 20px;
}
.submit-btn{
    margin-right: 100px;
}