.col{
    font-size: 12px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.table-row{
    font-size: 12px;
}

.table td, .table th{
    padding: 5px;
}
