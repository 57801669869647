.btn{
    font-size: 12px;
    margin-left: 10px;
}

.col{
    width: 100%;
    line-height:-2vh !important;
   
}

.Distance{
    position: relative;
    left: 70px;
    
}

.VerifyStatus{

    width: 25px;
    height: 25px;
}


.dashboard-right-panel .dashboard-right-panel-content .users-result-panel h3 {
  color: #333333;
  font-weight: 600;
  /* font-size: 1.9rem; */
  margin-bottom: 2rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper {
  background-color: #fff;
  border-radius: 1.8rem;
  -webkit-box-shadow: 2px 2px 4px #eff0f1;
          box-shadow: 2px 2px 4px #eff0f1;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table {
  width: 100%;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr th {
  /* font-size: 1.6rem; */
  color: #92979A;
  font-weight: 600;
  /* padding: 2rem 1.5rem; */
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr th:first-child {
  padding-left: 3rem !important;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td {
  /* font-size: 1.5rem; */
  color: #333333;
  font-weight: 500;
  /* padding: 2rem 1.5rem; */
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td p {
  /* font-size: 1.5rem; */
  line-height: 2.6rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td a.btn {
  /* padding: 1rem 2rem; */
  border-radius: .5rem;
  font-size: 1.5rem;
  color: #fff;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td a {
  color: #333333;
  font-weight: 500;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td:first-child {
  padding-left: 3rem !important;
  font-weight: bold;
  /* font-size: 1.7rem; */
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td .user-role {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td .user-role .user-role-list {
  width: calc(100% - 3rem);
  padding-right: 1rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td .user-role .user-role-list p {
  /* font-size: 1.6rem; */
  margin-bottom: 0rem;
  line-height: 2.6rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td .user-role .add-user-role {
  width: 3rem;
  /* font-size: 2rem; */
  color: #0056A1;
}



.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td.tableRow-edit-delete {
  visibility: hidden;
  width: 7rem;
  padding-left: 1rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td.tableRow-edit-delete a {
  text-decoration: none;
  display: inline-block;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td.tableRow-edit-delete a:last-child {
  margin-left: 1.5rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td.tableRow-edit-delete a img {
  width: 2rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td.description-col {
  width: 40%;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tr td.description-col p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tbody tr:hover {
  background: #f7f9f7;
  background: -webkit-gradient(linear, left top, right top, from(#f7f9f7), color-stop(80%, #f7f9f7), color-stop(100%, #bcf3f9), to(#f7f9f7));
  background: linear-gradient(90deg, #f7f9f7 0%, #f7f9f7 80%, #bcf3f9 100%, #f7f9f7 100%);
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .user-result-table table tbody tr:hover .tableRow-edit-delete {
  visibility: visible;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .table-pagination-panel {
  padding: 3rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .table-pagination-panel p {
  margin-bottom: 0;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .table-pagination-panel .pagination {
  padding-left: 2rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .table-pagination-panel .pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .table-pagination-panel .pagination ul li {
  list-style: none;
  margin: 0 .5rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .table-pagination-panel .pagination ul li a {
  border: 1px solid #E5E9F2;
  width: 4.7rem;
  height: 4.7rem;
  line-height: 4.7rem;
  /* font-size: 1.8rem; */
  font-weight: 600;
  color: #333333;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 1rem;
}

.dashboard-right-panel .dashboard-right-panel-content .users-result-panel .user-result-table-wrapper .table-pagination-panel .pagination ul li.active a {
  background-color: #0056A1;
  color: #BCF3F9;
}
.nullLocation{
  background-color: #dcd0ff;
}
.table-pagination-home {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-pagination-home .previous,
.table-pagination-home .next {
  width: 20px;
  height: 20px;
  text-align: center;
  align-items: center;
  background-color: #015ce9;
  border-radius: 4px;
  margin: 0px 8px;
}
.table-pagination-home li.active a {
  background: #ffffff;
  border: 1px solid rgba(52, 52, 52, 0.5);
  border-radius: 8px;
  padding: 5px 10px;
}

.table-pagination-home li {
  margin: 0px 10px;
}

.table-pagination-home li a {
  font-family: 'Inter';
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #222222;
}

.table-pagination-home li.previous.disabled,
.table-pagination-home li.next.disabled {
  background-color: #015ce9;
}