





    /* .sidebar {
        width: 8rem!important;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    } */

    @media (max-width:767px){
        
    .sidebar.collapsed {
        /* width: 15rem!important; */ display: none;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    }
        .sidebar {
            width: 12rem !important;
             display: block;
            transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
        }
    }

        .toggle-area{display: none;}
        @media (min-width:767px){}
