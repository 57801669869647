:root {
  --PhoneInputCountryFlag-height: 2em !important;
  --PhoneInputCountrySelectArrow-opacity: 1px !important;
  --PhoneInputCountrySelectArrow-width: 0.5em !important;
}

.PhoneInputInput {
  height: 40px;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

#formContent {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 0px;
  background: url(../../new-bg.jpg) top center no-repeat;
  background-size: cover;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 1000;
  text-transform: uppercase;
  display: inline-block;
  margin: 4px 8px 10px 8px;
}

.fadeIn.first {
  padding: 5px;
  
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
}
.fadeIn.first h4 {
  color: #000;
  margin: 0;
  padding: 10px; font-weight: 600;
}

.btn {
  height: 40px;
  text-transform: uppercase;
}

.input {
  width: 74%;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  border: 1px solid #ccc;
} 

.form-inner {
  padding: 0px 30px 30px 30px;    text-align: center;
  background: #fff;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
  width: 60%;
  border-radius: 10px;
  margin: 0 auto;
}

.loginBtn {
  background: #015ce8;
  color: #fff;
  font-weight: 600;
  width: 20%;
}
.loginBtn:hover{color: #fff;}