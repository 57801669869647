.form .form-control {
    width: 100%;
    height: 40px;
}
.form .btn{
    width: 100%;
    height: 40px;
    margin: 0;font-weight: 600;    font-size: 14px;
}
.form label {
    justify-content: flex-start !important;
    padding: 0 !important;
}


.form label.downldExcl {
    justify-content: center !important;
  
}
button.btn.btn-success.btnSearch {
   
    border: none;
    box-shadow: none;
}
button.btn.btn-primary.category-button {
    width: 100%;
    margin-top: 37px;
}
button.btn.btn-primary.btnNotification {
    margin-top: 30px;
}
